<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <b-col cols="12" md="4" class="d-flex align-items-left justify-content-end mb-1 mb-md-0 ">
              <v-select :options="accountTypeOptions" v-model="accountType" class="per-page-selector d-inline-block w-100" label="text" :reduce="(val) => val.value" placeholder="Account Type" />
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/customer/customer-add" v-if="$Can('customer_create')">
                  <span class="text-nowrap">Add Customer</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listCustomers"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="customerHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
          </template>
          <!-- Column: firstName -->
          <template #cell(firstName)="data"> {{ data.item.firstName ? data.item.firstName : 'N/A' }} </template>
          <!-- Column: lastName -->
          <template #cell(lastName)="data"> {{ data.item.lastName ? data.item.lastName : 'N/A' }}</template>
          <!-- Column: email -->
          <template #cell(email)="data"> {{ data.item.email ? data.item.email : 'N/A' }} </template>
          <!-- Column: sellerType -->
          <template #cell(sellerType)="data">
            <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template>
          <!-- Column: phoneNumber -->
          <template #cell(phoneNumber)="data"> {{ data.item.phoneNumber ? data.value : 'N/A' }} </template>
          <!-- Column: driverLicenseNumber -->
          <template #cell(driverLicenseNumber)="data">{{ data.item.driverLicenseNumber ? data.item.driverLicenseNumber : 'N/A' }} </template>
          <!-- Column: city -->
          <template #cell(city)="data"> {{ data.item.city ? data.item.city : 'N/A' }} </template>
          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="success" class="badge-glow">Active</b-badge>
            <b-badge v-if="data.item.status == 'B'" pill variant="secondary" class="badge-glow">Passive</b-badge>
          </template>

          <!-- Column: dealer -->
          <template #cell(dealer)="data"> {{ data.item.dealer ? data.item.dealer : 'N/A' }} </template>
          <!-- Column: dateOfBirth -->
          <template #cell(dateOfBirth)="data"> {{ data.item.dateOfBirth ? data.item.dateOfBirth : 'N/A' }} </template>
          <!-- Column: unitNumber -->
          <template #cell(unitNumber)="data"> {{ data.item.unitNumber ? data.item.unitNumber : 'N/A' }} </template>
          <!-- Column: postalCode -->
          <template #cell(postalCode)="data"> {{ data.item.postalCode ? data.item.postalCode : 'N/A' }} </template>
          <!-- Column: provence -->
          <template #cell(provence)="data">{{ data.item.provence ? data.item.provence : 'N/A' }} </template>
          <!-- Column: country -->
          <template #cell(country)="data"> {{ data.item.country ? data.item.country : 'N/A' }} </template>
          <!-- Column: otherIdNumber -->
          <template #cell(otherIdNumber)="data"> {{ data.item.otherIdNumber ? data.item.otherIdNumber : 'N/A' }} </template>
          <!-- Column: gst -->
          <template #cell(gst)="data"> {{ data.item.gst ? data.item.gst : 'N/A' }}</template>
          <!-- Column: pst -->
          <template #cell(pst)="data"> {{ data.item.pst ? data.item.pst : 'N/A' }} </template>
          <!-- Column: updated_at -->
          <template #cell(updated_at)="data"> {{ data.item.updated_at ? dateFormat(data.item.updated_at) : 'N/A' }} </template>
          <!-- Column: created_at -->
          <template #cell(created_at)="data"> {{ data.item.created_at ? dateFormat(data.item.created_at) : 'N/A' }} </template>

          <!-- <template #cell(sellerType)="data">
            <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'customer-edit', params: { id: data.item.id } }" v-if="$Can('customer_show')">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'customer-edit', params: { id: data.item.id } }" v-show="where == 'customer-list'" v-if="$Can('customer_edit')">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="sendToArchive(data.item.id, data)" v-show="$Can('customer_send_archive')" v-if="data.item.archive == 'no'">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_customer_archive_records')" v-if="where == 'customer-list'" @click="$router.push({ name: 'archive-customer-list' })">Show archived records</a>
        <a class="ml-2 mb-2 mr-2 float-right text-primary" v-show="$Can('view_customer_archive_records')" v-else @click="$router.push({ name: 'customer-list' })">Show active records</a>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import customerStoreModule from '../customerStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: { vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup },

  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer';

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const accountTypeOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'Individual', value: '1' },
      { text: 'Business', value: '2' },
    ];

    return {
      where: router.currentRoute.name,
      filter: null,
      perPage: localStorage.getItem('customerShow') ?? 10,
      fetchCustomers: [],
      listCustomers: [],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,

      baseURL: store.state.app.baseURL,
      loading: false,
      accountTypeOptions,
      customerHeaders: [],
      accountType: null,
      status: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    // accountType: {
    //   handler: function(val, before) {
    //     this.listCustomers = [];
    //     this.fetchCustomers.forEach((element) => {
    //       if (val == null) {
    //         this.listCustomers = this.fetchCustomers;
    //       } else {
    //         if (element.sellerType == val) {
    //           this.listCustomers.push(element);
    //         }
    //       }
    //       this.totalRows = this.listCustomers.length;
    //     });
    //   },
    // },

    perPage: {
      handler: function(val, before) {
        localStorage.setItem('customerShow', val);

        this.listRefresh();
      },
    },

    accountType: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.listRefresh();
        }
      },
    },
  },

  methods: {
    onRowSelected(item) {
      router.push({ name: 'customer-edit', params: { id: item[0].id } });
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD-MM-YYYY HH:mm');
      }
    },

    listRefresh() {
      if (this.where == 'customer-list') {
        this.getItems('fetchCustomers');
      } else {
        this.getItems('fetchArchiveCustomers');
      }
    },
    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getItems(val) {
      this.loading = true;
      store
        .dispatch('customer/' + val, { perPage: this.perPage, accountType: this.accountType, page: this.currentPage, filter: this.filter, sortBy: this.sortBy, sortDesc: this.sortDesc })
        .then((res) => {
          this.createTable(res.data.headers, res.data.customer_columns);
          this.fetchCustomers = res.data.data.data;
          this.listCustomers = res.data.data.data;
          this.totalRows = res.data.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.customerHeaders.push({ key: items, label: columns.title, sortable: true, class: 'text-center', thStyle: width });
        });
        this.customerHeaders.push({ key: 'actions', class: 'text-center', thStyle: 'width: 5%' });
        this.loading = false;
      }
    },

    sendToArchive(val, data) {
      this.loading = true;
      store.dispatch('customer/customerSendToArchive', val).then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ The Quote sent to archive',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
        this.listCustomers.splice((this.currentPage - 1) * this.perPage + data.index, 1);
        this.totalRows--;
        this.currentPage = 1;
        this.loading = false;
      });
    },
  },

  mounted() {
    this.listRefresh();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
